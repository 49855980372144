// To enable chatbot agent, this file need to remove

//consoleLog('=== Rule fired: SF chat - global code');
if (!window.embedded_svc) {
    var s = document.createElement("script");
    s.setAttribute(
        "src",
        $('#chatScriptsEswUrl').attr('data-value')
    );
    s.onload = function () {
        //consoleLog('=== SF Chat JS file esw.min.js loaded');
        if (window.initESW) {
            initESW(null);
            //consoleLog('=== SF chat - initESW called');
        }
        window.dataLayer.push({
            'event': 'salesforce-chat-initialized'
            //pass more vars here
        });
        //add cookie logic here
    };
    document.body.appendChild(s);
} else {
    initESW($('#chatScriptsEswServiceUrl').attr('data-value'));
}

// Set chat cookie by Chat Type
$(document).on('click', '.embeddedServiceHelpButton .helpButtonEnabled', function () {
    if(window.location.href.indexOf('/myaccount/') > -1 ) {
        setCookie("sf_chat_initiate","myaccount",$('#chatScriptsCookieDays').attr('data-value'),"",true);
    } else {
        setCookie("sf_chat_initiate","sales",$('#chatScriptsCookieDays').attr('data-value'),"",true);
    }
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': 'custom-event',
        'dlCategory' : 'hybris_sfchat_initiated',
        'dlAction': window.chatOffered,
        'dlLabel' : window.location.pathname
    });
});