// Clear sf_chat_initiate cookie if Chat session destroyed when closing tab/browser
let activeChatSession;
let storageKey = $('#chatSettingsStorageDomain').attr('data-value');
if(window.location.href.indexOf('localhost') > -1) {
    activeChatSession = sessionStorage.getItem('localhostMASTER_DEPLOYMENT_ID');
} else {
    activeChatSession = sessionStorage.getItem(storageKey.concat('MASTER_DEPLOYMENT_ID'));
}
if(isNullUndefined(activeChatSession) || isFalse(activeChatSession)) {
    deleteCookie('sf_chat_initiate');
}

window.salesForceChatEnabled = true;
window.initESW = function (gslbBaseURL) {
    let custSessionId = '', guid = '', storeNum = '', preApproved = false;
    let userStatusLogin = 'No';
    let datalayer;
    let pairs = document.cookie.split(";");
    let cookies = {};
    for (let i = 0; i < pairs.length; i++) {
        let pair = pairs[i].split("=");
        if ((pair[0] + '').trim() == '_ga') {
            custSessionId = pair[1];
            custSessionId = custSessionId.slice(6);
        }
    }
    if (typeof RAC != 'undefined') {
        guid = RAC.coreDataLayer.hfLeadUniqueId;
        storeNum = RAC.coreDataLayer.storeNumber;
        preApproved = RAC.coreDataLayer.preApproved;
        userStatusLogin = RAC.coreDataLayer.loggedInStatus;

        let dataLength = window.myPagesData;
        if (dataLength && dataLength.length >= 1) {
            consoleLog("----IndexedDB Data Populated----");
            datalayer = JSON.stringify(window.myPagesData);
        } else {
            datalayer = "No Data Gathered";
            consoleLog("-----No IndexedDB Data Gathered:------- ");
        }
    }
    embedded_svc.settings.displayHelpButton = isTrue($('#chatSettingsDisplayHelpButton').attr('data-value')); //Or false
    embedded_svc.settings.language = $('#chatSettingsLanguage').attr('data-value'); //For example, enter 'en' or 'en-US'

    embedded_svc.settings.defaultMinimizedText = $('#chatSettingsDefaultMinimizedText').attr('data-value'); //(Defaults to Chat with an Expert)
    //embedded_svc.settings.disabledMinimizedText = '...'; //(Defaults to Agent Offline)
    //embedded_svc.settings.loadingText = ''; //(Defaults to Loading)
    if(window.location.href.indexOf('localhost') > -1) {
        embedded_svc.settings.storageDomain = "localhost"; //(Sets the domain for your deployment so that visitors can navigate subdomains during a chat session)
    } else {
        embedded_svc.settings.storageDomain = $('#chatSettingsStorageDomain').attr('data-value'); //(Sets the domain for your deployment so that visitors can navigate subdomains during a chat session)
    }
    // Settings for Chat
    //embedded_svc.settings.directToButtonRouting = function(prechatFormData) {
    // Dynamically changes the button ID based on what the visitor enters in the pre-chat form.
    // Returns a valid button ID.
    //};
    //embedded_svc.settings.prepopulatedPrechatFields = {}; //Sets the auto-population of pre-chat form fields
    //embedded_svc.settings.fallbackRouting = []; //An array of button IDs, user IDs, or userId_buttonId
    //embedded_svc.settings.offlineSupportMinimizedText = '...'; //(Defaults to Contact Us)
    //consoleLog("----About to Pass data into Hybris_Lead_Data_RAC__c:----- "+ datalayer);
    embedded_svc.settings.extraPrechatFormDetails = [{
        "label": "URL",
        "value": document.location.href,
        "displayToAgent": false,
        "transcriptFields": ["Chat_Initiated_From_URL_RAC__c"]
    },
    {
        "label": "data Layer",
        "value": datalayer,
        "displayToAgent": false,
        "transcriptFields": ["Hybris_Lead_Data_RAC__c"]
    },
    {
        "label": "GUID",
        "value": guid,
        "displayToAgent": false,
        "transcriptFields": ["WL_Web_Order_Id_RAC__c"]
    },
    {
        "label": "Customer Session Id",
        "value": custSessionId,
        "displayToAgent": false,
        "transcriptFields": ["WL_Customer_Web_Session_Id_RAC__c"]
    },
    {
        "label": "Store Number",
        "value": storeNum,
        "displayToAgent": false,
        "transcriptFields": ["Store_Number_RAC__c"]
    },
    {
        "label": "preApproved",
        "value": preApproved,
        "displayToAgent": false,
        "transcriptFields": ["WL_Preapproved_Flag_RAC__c"]
    },
    {
        "label": "logged In Status",
        "value": userStatusLogin,
        "displayToAgent": false,
        "transcriptFields": ["WL_Logged_In_Status_RAC__c"]
    }];

    embedded_svc.settings.enabledFeatures = $('#chatSettingsEnabledFeatures').attr('data-value').split(',');
    embedded_svc.settings.entryFeature = $('#chatSettingsEntryFeature').attr('data-value');

    let chatCookiePlacement = getCookieByName('sf_chat_initiate');
    let loadOnlySalesChat = getCookieByName('loadOnlySalesChat');
    let loadOnlyMyAccountChat = getCookieByName('loadOnlyMyAccountChat');

    if(isTrue(loadOnlySalesChat) && isTrue(loadOnlyMyAccountChat)) {
        return false;
    } else {
        if(isTrue(loadOnlySalesChat)) {
            loadSfChat(gslbBaseURL);
        } else if(isTrue(loadOnlyMyAccountChat)) {
            loadMyAccountChat(gslbBaseURL);
        } else {
            if ((window.location.href.indexOf('/myaccount/') > -1 && chatCookiePlacement != 'sales') || chatCookiePlacement == 'myaccount') {
                loadMyAccountChat(gslbBaseURL);
            } else {
                loadSfChat(gslbBaseURL);
            }
        }
    }

}

function loadSfChat(gslbBaseURL) {
    console.log('+++=== Chat Init as SF =====+++');
    window.chatOffered = "sales_chat";
    embedded_svc.init(
        $('#sfChatInitParamFirst').attr('data-value'),
        $('#sfChatInitParamSecond').attr('data-value'),
        gslbBaseURL,
        $('#sfChatInitParamFourth').attr('data-value'),
        $('#sfChatInitParamChatType').attr('data-value'),
        {
            baseLiveAgentContentURL: $('#sfChatInitBaseLiveAgentContentURL').attr('data-value'),
            deploymentId: $('#sfChatInitDeploymentId').attr('data-value'),
            buttonId: $('#sfChatInitButtonId').attr('data-value'),
            baseLiveAgentURL: $('#sfChatInitBaseLiveAgentURL').attr('data-value'),
            eswLiveAgentDevName: $('#sfChatInitEswLiveAgentDevName').attr('data-value'),
            isOfflineSupportEnabled: isTrue($('#sfChatInitIsOfflineSupportEnabled').attr('data-value'))
        }
    );
}
function loadMyAccountChat(gslbBaseURL) {
    console.log('+++=== Chat Init as My Account=====+++');
    window.chatOffered = "myaccount_chat";
    embedded_svc.init(
        $('#accChatInitParamFirst').attr('data-value'),
        $('#accChatInitParamSecond').attr('data-value'),
        gslbBaseURL,
        $('#accChatInitParamFourth').attr('data-value'),
        $('#accChatInitParamChatType').attr('data-value'), {
            baseLiveAgentContentURL: $('#accChatInitBaseLiveAgentContentURL').attr('data-value'),
            deploymentId: $('#accChatInitDeploymentId').attr('data-value'),
            buttonId: $('#accChatInitButtonId').attr('data-value'),
            baseLiveAgentURL: $('#accChatInitBaseLiveAgentURL').attr('data-value'),
            eswLiveAgentDevName: $('#accChatInitEswLiveAgentDevName').attr('data-value'),
            isOfflineSupportEnabled: isTrue($('#accChatInitIsOfflineSupportEnabled').attr('data-value'))
        }
    );
}

window.dataLayer.push({
    'event': 'salesforce-chat-snippet-loaded'
});